'use client';

import { useEffect } from 'react';

import ErrorFallback from './_components/error-boundary-fallback/ErrorBoundaryFallback';

interface ErrorPageProps {
  error: Error;
  reset: () => void;
}

export default function GlobalError({ error }: ErrorPageProps) {
  useEffect(() => {
    console.error('Error:', error);
  }, [error]);

  return (
    <html>
      <body>
        <ErrorFallback error={error} />
      </body>
    </html>
  );
}
